import { Lockdown } from 'interfaces/lockdown.interface';

import * as actions from '../actions/lockdowns';

interface State {
  lockdowns: Lockdown[];
}

const initialState: State = {
  lockdowns: []
};

type Action = { type: 'SET_LOCKDOWNS', payload: Lockdown[] };

const lockdownsReducer = (
  state = initialState,
  action: Action,
) => {
  switch (action.type) {
    case actions.SET_LOCKDOWNS:
      return {
        ...state,
        lockdowns: [...action.payload],
      };

    default:
      return state;
  }
};

export default lockdownsReducer;
