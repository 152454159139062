import { useEffect, useState } from 'react';

// Material UI
import { Box, IconButton, Stack, Theme, useMediaQuery } from '@mui/material';

// Store
import { useDispatch, useSelector } from 'store';
import { changeProfile } from 'store/actions/user';
// Imports
import user from 'interfaces/user.interface';
import { getDealersImg, getOrganizationDetail } from 'api/api';
import Avatar from 'components/Avatar/Avatar';
import UserInfoButton from './UserInfoButton/UserInfoButton';
import SelectionNav from './SelectionNav/SelectionNav';
import SelectionNavMini from './SelectionNavMini/SelectionNavMini';
import Clock from './Clock/Clock';
import placeholder from 'assets/images/media/avatars/logo_placeholder.png';
import lockdownImg from 'assets/images/dashboard_icons/lockdown.png';
import { useDisplayNav } from 'hooks/useDisplayNav';
import { useHelpLinks } from 'providers/HelpLinksProvider';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useSelectedSiteLockdownStatus } from 'hooks/useSelectedSiteLockdownStatus';

const NavigationBar = ({
  open,
  setMenu
}: {
  open: boolean;
  setMenu: (menu: any) => void;
}) => {
  const user: user = useSelector((store) => store.user.user);
  const dispatch = useDispatch();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const displayNav = useDisplayNav();
  const {
    links: { dashboard_help_link }
  } = useHelpLinks();

  const [organization, setOrganization] = useState<any>();

  const { isLockdownActive } = useSelectedSiteLockdownStatus();

  useEffect(() => {
    fecthOrgnization();
  }, []);

  useEffect(() => {
    user.selectedDealer?.dealer && fetchImage();
  }, [user.selectedDealer?.dealer]);

  const fecthOrgnization = async () => {
    try {
      const { status, data } = await getOrganizationDetail(
        user.selectedOrg?.organization || user.currentProfile
      );

      if (status === 200) {
        setOrganization({
          ...data
        });
      }
    } catch (error: any) {}
  };

  const fetchImage = async () => {
    try {
      const { image } = await getDealersImg(
        String(user.selectedDealer?.dealer)
      );

      dispatch(
        changeProfile(
          user.permissions,
          user.role,
          user.role_name,
          user.currentProfile,
          user.profile_type,
          user.selectedOrg,
          {
            ...user.selectedDealer,
            image: image ? `data:image/jpeg;base64,${image}` : ''
          }
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{ alignItems: 'center' }}
        justifyContent="space-between"
        width="100%"
      >
        {displayNav ? (
          <Box pl={matches ? (open ? 1 : 0) : open ? 0 : 4}>
            {matches ? <SelectionNav /> : <SelectionNavMini />}
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              className="transparent-avatar"
              variant="rounded"
              sx={{ width: 40, height: 40, marginTop: 1 }}
              src={
                organization?.logo
                  ? `data:image/png;base64,${organization?.logo}`
                  : placeholder
              }
            >
              <img
                width={50}
                height={50}
                src={placeholder}
                alt="Account avatar"
              />
            </Avatar>
            <Box pl={1}>
              {matches ? <SelectionNav /> : <SelectionNavMini />}
            </Box>
          </Box>
        )}
        {/* User section and settings */}
        <Box
          sx={{ flexGrow: 0, display: 'flex', gap: 1, alignItems: 'center' }}
        >
          {isLockdownActive && (
            <Box className="blinkcard-yellow" padding={1}>
              <img
                src={lockdownImg}
                width={32}
                alt="Lockdown"
                style={{ marginLeft: '2px' }}
              />
            </Box>
          )}
          {dashboard_help_link && (
            <IconButton href={dashboard_help_link} target="_blank">
              <HelpOutlineIcon sx={{ fontSize: 28, color: 'white' }} />
            </IconButton>
          )}
          {matches && <Clock />}
          <UserInfoButton setMenu={setMenu} />
        </Box>
      </Stack>
    </>
  );
};
export default NavigationBar;
