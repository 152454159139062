import { AnyAction } from '@reduxjs/toolkit';
import {
  SET_USER,
  REMOVE_USER,
  CHANGE_PROFILE,
  UPDATE_SELECTED_ORG,
  SET_TOKEN
} from '../actions/user';
import { IAction, IPermission } from 'interfaces/permissions.interface';
import user from 'interfaces/user.interface';

/**
 * Creates an action map based on the given permissions.
 *
 * @param permissions - The array of permissions.
 * @returns The action map object.
 */
const createActionMap = (permissions: IPermission[]) => {
  const actionMap: Record<string, boolean> = {};

  permissions.forEach((permission) => {
    Object.entries(permission).forEach(([key, value]) => {
      if (key.startsWith('action') && typeof value === 'object') {
        const action = value as IAction;
        if (!action.tag) return;

        actionMap[action.tag.toLowerCase()] = action.active;
      }
    });
  });

  return actionMap;
};

// Initial state
const initialState: {
  token: string | null;
  user: user | undefined;
  actionMap: Record<string, boolean>;
} = {
  token: null,
  user: undefined,
  actionMap: {}
};

// Reducer
const UserReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_USER:
      const { user } = action;
      const setUserActionMap = createActionMap(user.permissions);
      let userToSet = { ...user };

      if (user.user_profiles.length === 1) {
        userToSet = {
          ...user,
          currentProfile: user.user_profiles[0].dealer_org_id,
          profile_type: user.user_profiles[0].profile_type,
          selectedDealer:
            user.user_profiles[0].profile_type === 'dealer'
              ? {
                  dealer: user.user_profiles[0].dealer_org_id,
                  name: user.user_profiles[0].company_org_name,
                  image: user.selectedDealer?.image
                }
              : null
        };
      }

      return {
        ...state,
        user: userToSet,
        actionMap: setUserActionMap
      };

    case SET_TOKEN:
      const { token } = action;
      return {
        ...state,
        token
      };

    case REMOVE_USER:
      return initialState;

    case CHANGE_PROFILE:
      const {
        permissions,
        role,
        role_name,
        dealer_org_id,
        profile_type,
        selectedOrg,
        selectedDealer
      } = action;

      const changeProfileActionMap = createActionMap(permissions);
      return {
        ...state,
        user: {
          ...state.user,
          permissions,
          role,
          role_name,
          currentProfile: dealer_org_id,
          profile_type: profile_type,
          selectedOrg: selectedOrg,
          selectedDealer: selectedDealer
        },
        actionMap: changeProfileActionMap
      };

    case UPDATE_SELECTED_ORG:
      return {
        ...state,
        user: {
          ...state.user,
          selectedOrg: {
            ...state.user?.selectedOrg ?? {},
            ...action.selectedOrg,
          },
        },
      };

    default:
      return state;
  }
};

export default UserReducer;
