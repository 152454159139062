import {
  combineReducers,
  configureStore,
  PreloadedState
} from '@reduxjs/toolkit';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';

import controllerSdkReducer from './reducers/controller-sdk';
import hardwareInputsReducer from './reducers/hardware-inputs';
import hardwareOutputsReducer from './reducers/hardware-outputs';
import lockdownsReducer from './reducers/lockdowns';
import selectedSiteReducer from './reducers/selected-site';
import snackAlertReducer from './reducers/snack-alert';
import summaryReducer from './reducers/summary';
import supportSessionReducer from './reducers/support-session';
import userReducer from './reducers/user';

const rootReducer = combineReducers({
  controllerSdk: controllerSdkReducer,
  hardwareInputs: hardwareInputsReducer,
  hardwareOutputs: hardwareOutputsReducer,
  lockdowns: lockdownsReducer,
  selectedSite: selectedSiteReducer,
  snackAlert: snackAlertReducer,
  summary: summaryReducer,
  supportSession: supportSessionReducer,
  user: userReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'] // Reducers to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,

  // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
  });
}

export const persistor = persistStore(store);

// Store types
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

// Hooks
export const useDispatch: () => AppDispatch = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
