const dealers = {
  header: 'Liste des concessionnaires',
  dealers: 'Concessionnaires',
  employee: 'Employé',
  edit_dealers: 'Modifier le concessionnaire',
  dealers_details: 'Détails du concessionnaire',
  add_dealers: 'Nouveau Concessionnaire',
  add_emp: 'Nouvel Employé',
  edit_emp: 'Employé',
  search_dealers: 'Rechercher des revendeurs',
  search_employee: 'Rechercher des Employé',
  search_organizations: 'Rechercher des comptes',
  delete_dealers_message: 'Etes-vous sûr que vous voulez supprimer',
  selected_dealerss: 'les revendeurs sélectionnés',
  confirm_modal_cancel: 'Non, annuler',
  confirm_modal_confirm: 'Oui, supprimer',
  create_dealer: 'Nouveau Concessionnaire',
  update_dealer: 'Concessionnaire',
  dealer_info: 'Informations sur le revendeur',
  your_dealer_info: 'Informations sur votre revendeur',
  review_dealer_account: 'Examiner le compte du concessionnaire',
  all: 'Tous',
  active: 'Actif',
  inactive: 'Inactif',
  submit: 'Soumettre',
  cancel: 'Annuler',
  update: 'Mise à jour',
  independet: 'Revendeur indépendant',
  national: 'Concessionnaire compte national',
  na: 'Revendeur NA associé',
  back_to_list: 'Retour à la liste',
  edit: 'Modifier',
  profile: 'Profil',
  organizations: 'Comptes',
  controllers: 'Contrôleurs',
  doors: 'Des portes',
  users: 'Utilisateurs',
  invoices: 'Factures',
  myplan: 'Mon Plan',
  dealer_updated: 'Concessionnaire mis à jour',
  dealer_role: 'Rôle de concessionnaire',
  emp_role: 'Rôle de employé',
  set_dealer: 'Concessionnaire d\'ensemble',
  change_dealer_msg: 'Voulez-vous changer de revendeur ?',
  select_dealer_msg:
    'Veuillez vous rendre sur Revendeurs et en sélectionner un',
  confirm_action: 'Etes-vous sûr de vouloir poursuivre cette action ?',
  manage_plan: 'Gérer mon forfait',
  add_employee: 'Ajouter un employé',
  your_employees_info: 'Informations sur votre employé',
  calculate_for_account: 'Calculez pour vos comptes',
  how_many_doors: 'Combien de portes',
  for_doors: 'Pour une ou plusieurs portes',
  doors_to_add: 'Combien de licences de portes souhaitez-vous ajouter ?',
  door_licenses_available: 'Permis de porte disponible',
  billing_cycle: 'Cycle de facturation',
  card_enrollment: 'Inscription de la certificat',
  card_information: 'Informations sur la certificat',
  access_all_accounts: 'Accès à tous les comptes',
  remove_access_all: 'Supprimer l\'accès à tous les comptes',
  site_monitor: 'Moniteur de chantier',
  how_you_discover: 'Comment avez-vous découvert CDVI Cloud',
  no_alerts_reported: 'Aucune alerte signalée',
  remove_access_msg: 'Êtes-vous sûr de vouloir supprimer l\'accès à {{name}}?',
  grant_all_access: 'Voulez-vous accorder l\'accès à tous les comptes?',
  remove_all_access: 'Voulez-vous supprimer l\'accès à tous les comptes?',
  set_permissions: 'Voulez-vous configurer les autorisations?',
  dealer_registration: 'Inscription du concessionnaire',
  assign_site: 'Assign Site',
  validate: {
    type_email: 'S\'il vous plaît, tapez votre e-mail',
    approved_email: 'Adresse e-mail approuvée',
    verification_code: 'Le code de vérification',
    validation_label:
      'Un mot de passe à usage unique a été envoyé à votre adresse e-mail, veuillez vérifier votre boîte de réception',
    done: 'Succès',
    success: 'Cliquez ci-dessous pour vous connecter à votre compte CDVI Cloud',
    continue: 'Continuer',
    finish: 'Finir',
    password_must_contain: 'Votre mot de passe doit contenir',
    number_of_charachters: '8 à 24 caractères',
    lowercase: 'Une lettre minuscule',
    uppercase: 'Une lettre majuscule',
    number: 'Un numéro',
    special_character: 'Un personnage particulier',
    password_match: 'Correspondance du mot de passe'
  },
  table: {
    company: 'ENTREPRISE',
    full_name: 'NOM ET PRÉNOM',
    email: 'E-MAIL',
    country: 'PAYS',
    last_login: 'DERNIÈRE CONNEXION',
    active: 'ACTIF'
  },
  form: {
    type: 'Type de concessionnaire',
    company: 'Entreprise',
    full_name: 'Nom et prénom',
    first_name: 'Prénom',
    last_name: 'Nom de famille',
    email: 'E-mail',
    confirm_email: 'Confirmez E-mail',
    country: 'Pays',
    address: 'Adresse',
    city: 'Ville',
    state: 'État',
    province_territory: 'Province ou territoire',
    zip_code: 'Code postal',
    postal_code: 'Code postal',
    phone: 'Téléphone',
    mobile: 'Mobile',
    language: 'Langue de communication',
    other: 'Autre'
  },
  error: {
    invalid_email: 'Format d\'email invalide',
    invalid_zip: 'Code postal invalide'
  },
  add_edit: {
    add_modal: 'Nouveau Utilisateur',
    edit_modal: 'Utilisateur',
    success: 'Utilisateur revendeur ajouté avec succès',
    update: 'L\'utilisateur du revendeur a été mis à jour avec succès',
    error: 'Erreur lors de l\'ajout de l\'utilisateur revendeur'
  },
  preregistration_success:
    'Nous vous avons envoyé un lien pour compléter l\'inscription à votre adresse e-mail',
  done: 'OK',
  preregistration: {
    submit: 'Envoyer'
  },
  user_activation: 'Activer l\'utilisateur'
};

export default dealers;
