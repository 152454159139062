const users = {
  header: 'Liste des utilisateurs',
  users: 'Utilisateurs',
  credentials: 'Identifiants',
  view_roles: 'Afficher les détails des rôles',
  edit_user: 'Utilisateur',
  user_details: 'Détails de l\'utilisateur',
  add_user: 'Nouvel Utilisateur',
  search_user: 'Rechercher un utilisateur',
  delete_user_message:
    'Etes-vous sûr que vous voulez supprimer {{user}} <br> Si l\'utilisateur a des certificats associées, celles-ci seront également non attribué.',
  delete_morethan_one_site: 'REMARQUE: Cet utilisateur est associé à plus d\'un site, il sera supprimé de tous.',
  selected_users: 'les utilisateurs sélectionnés',
  confirm_modal_cancel: 'Non, annuler',
  confirm_modal_confirm: 'Oui, supprimer',
  remove_login: 'Supprimer la Connexion',
  create_login: 'Créer une connexion',
  all_doors: 'Toutes les portes',
  select_doors: 'Sélectionnez les portes',
  remove_confirmation_msg:
    'Voulez-vous vraiment supprimer l\'identifiant de l\'utilisateur?',
  activate_confirm_msg: 'Voulez-vous vraiment {{action}} {{user}}',
  role_confirm_msg: 'Êtes-vous sûr de vouloir définir le rôle {{role}}?',
  access_levels: 'Niveaux d\'accès',
  set_keypad: 'Ensemble Keypad Code',
  has_keypad: 'A Keypad',
  no_keypad: 'Pas de Keypad',
  remove_keypad: 'Supprimer le code du clavier',
  remove_keypad_msg:
    'Voulez-vous vraiment supprimer le code du clavier attribué à {{user}}?',
  pending_changes:
    'Il y a des modifications en attente à enregistrer. Êtes-vous sûr de vouloir quitter cette page?',
  no_custom_fields: 'Aucun champ personnalisé n\'est configuré pour ce site.',
  remove_last_site_note:
    'NOTE : La suppression de l\'accès à ce site entraînera également la suppression de l\'utilisateur de l\'ensemble du compte.',
  remove_last_site_with_access_note:
    'NOTE : Vous n\'avez pas accès aux autres sites de cet utilisateur. La suppression de l\'accès à ce site entraînera également la suppression de l\'utilisateur de votre liste d\'utilisateurs.',
  change_site_status:
    'Êtes-vous sûr de vouloir modifier l\'accès de cet utilisateur à {{site}} à {{status}}?',
  site_access: 'Accès au site',
  general_information: 'Informations générales',
  custom_fields: 'Champs personnalisés',
  custom_fields_success: 'Champs personnalisés enregistrés avec succès',
  table: {
    first_name: 'PRENOM',
    last_name: 'NOM DE FAMILLE',
    email: 'E-MAIL',
    user: 'UTILISATEUR',
    role: 'RÔLE',
    phone: 'TÉLÉPHONER',
    username: 'NOM D\'UTILISATEUR',
    last_login: 'DERNIÈRE CONNEXION',
    serial_number: 'NUMÉRO DE SÉRIE',
    ip: 'ADRESSE IP',
    web_port: 'PORT',
    status: 'STATUT',
    firmware_version: 'VERSION DU FIRMWARE',
    company: 'COMPAGNIE',
    name: 'NOM',
    website: 'SITE INTERNET',
    country: 'PAYS',
    timezone: 'FUSEAU HORAIRE',
    address: 'ADRESSE',
    access_level: 'NIVEAU D\'ACCÈS',
    activation_date: 'DATE D\'ACTIVATION',
    expiry_date: 'DATE D\'EXPIRATION',
    act_exp_date: 'DATE D\'ACTIVATION / EXPIRATION',
    keypad_code: 'CODE CLAVIER',
    organization_company_name: 'ORGANIZATION',
    dealer_company_name: 'CONCESSIONNAIRE'
  },
  form: {
    username: 'Nom d\'utilisateur',
    email: 'E-mail',
    phone_number: 'Numéro de téléphone',
    date_created: 'Date de création',
    date_updated: 'Date de mise à jour',
    first_name: 'Prénom',
    last_name: 'Nom de famille',
    new_password: 'Nouveau mot de passe',
    role: 'Rôle',
    select_role: 'Sélectionner un rôle'
  },
  profile: {
    username: 'Nom d\'utilisateur',
    full_name: 'Nom et Prénom',
    last_login: 'Dernière connexion',
    actions: 'Actions',
    location: 'Emplacements',
    device: 'Dispositif',
    ip_address: 'adresse IP',
    date_time: 'Date/heure',
    edit_profile: 'Editer le profil',
    profile: 'Détails de l\'utilisateur',
    cancel: 'Annuler',
    update: 'Mise à jour',
    login: 'Connexion',
    activities: 'Activités',
    refresh: 'rafraîchir',
    change_email: 'Changer l\'E-mail',
    send_code: 'Envoyer le code',
    new_email: 'Nouveau email',
    confirm_email: 'Confirmez votre e-mail',
    change_email_description:
      'Veuillez cliquer sur envoyer le code pour recevoir un code de confirmation',
    code: 'Code',
    code_expiration: 'Le code expire dans 10 minutes',
    send_new_code: 'Envoyer nouveau code',
    change: 'Changer',
    user_updated: 'Le profil utilisateur a été mis à jour',
    allowed_filetypes: 'types de fichiers autorisés: png, jpg, jpeg.',
    change_avatar: 'Changer d\'avatar',
    remove_avatar: 'Supprimer l\'avatar',
    avatar_modal_description: 'Voulez-vous vraiment supprimer votre avatar?',
    login_sessions: 'Sessions de connexion'
  },
  add: {
    user_information: 'Informations de l\'utilisateur',
    security: 'Sécurité',
    login: 'Connexion',
    first_name: 'Prénom',
    last_name: 'Nom de famille',
    country: 'Pays',
    city: 'Ville',
    state: 'État',
    zipcode: 'Code postal',
    address: 'Adresse',
    phone: 'Téléphone',
    mobile: 'Portable',
    email: 'Email',
    card: 'Certificat',
    assign_card: 'Attribuer une certificat',
    successfully_created: 'créé avec succès',
    none: 'Aucun',
    create_login: 'Créer une connexion',
    site: 'Site'
  },
  security: {
    allow_extend_time: 'Autoriser le temps de prolongation',
    override_anti_passback: 'Ignorer l\'anti-retour',
    can_arm: 'Peut armer',
    master_attribute_card_enrollment_mode:
      'Attribut principal - Mode d\'inscription de certificat',
    overrides_interlock: 'Annule le verrouillage',
    can_disarm: 'Peut désarmer',
    activation_date: 'Date d\'activation',
    expiration_date: 'Date d\'expiration',
    keypad_code: 'Code clavier',
    confirm_code: 'Confirmer le code',
    access_level: 'Niveau d\'accès',
    site: 'Site',
    all: 'Tout',
    no_access_levels: 'Aucun niveau d\'accès',
    door: 'Porte',
    schedule: 'Programme',
    options: 'Possibilités'
  },
  login: {
    create_login: 'Créer une connexion',
    update_login: 'Mettre à jour la connexion',
    username: 'Nom d\'utilisateur',
    last_login: 'Dernière connexion',
    status: 'Statut',
    access_to_all_sites: 'Accès à tous les sites',
    select_sites: 'Sélectionnez des sites',
    success: 'L\'utilisateur a été créé',
    error: 'Impossible de créer l\'utilisateur'
  },
  card: {
    assign_a_card: 'Attribuer une certificat',
    edit_card: 'Modifier la certificat',
    credential: 'Identifiant',
    due_date: 'Date d\'échéance',
    no_due_date: 'Pas de date d\'échéance',
    assign: 'Attribuer',
    unassign: 'Désattribuer',
    activation_date: 'Date d\'activation',
    expiry_date: 'Date d\'expiration',
    card_status: 'État',
    actions: 'Actions',
    active: 'Active',
    inactive: 'Inactive',
    lost: 'Perdue',
    stolen: 'Volée',
    unassign_confirmation:
      'Voulez-vous vraiment annuler l\'attribution de cette certificat',
    to: 'à',
    format: 'Format',
    card_number: 'Numéro de certificat',
    assign_card_confirm:
      'Voulez-vous attribuer la certificat {{number}} à {{username}}?',
    confirm_btn: 'Confirmer',
    cancel_btn: 'Annuler',
    display_name: 'Nom d\'affichage'
  },
  delete: {
    success: 'L\'utilisateur {{name}} a été supprimé avec succès',
    error: 'Impossible de supprimer'
  },
  error: {
    invalid_email: 'Format d\'email invalide',
    invalid_date: 'Format de date invalide',
    email_no_match: 'Email ne correspond pas',
    update_failed: 'Utilisateur mis à jour'
  },
  events: {
    tabTitle: 'Événements'
  },
  keypad: {
    success: 'Le code du clavier a été mis à jour avec succès',
    error: 'Impossible de mettre à jour le code du clavier',
    remove_confirm: 'Voulez-vous vraiment supprimer le code du clavier?',
    remove_success: 'Le code du clavier a été supprimé avec succès',
    remove_error: 'Impossible de supprimer le code du clavier',
    keypad_length: '{{length}} caractères requis',
    keypad_var_length: 'Minimum {{minimum}} / Maximum {{maximum}} caractères',
    code_mismatch: 'Le code du clavier doit correspondre',
    custom: 'Code clavier personnalisé',
    generate_new: 'Générer un nouveau code clavier',
    ask: 'Voulez-vous définir le code du clavier pour ce site {{site}}?'
  }
};

export default users;
