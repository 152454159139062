import { useState, useEffect } from 'react';
import routes from '../routes/routes';
import { AxiosResponse } from 'axios';

/**
 * Custom hook for managing support session windows.
 *
 * @param onWindowClose - Optional callback function to be executed when the window is closed.
 * @returns An object with the `openSessionWindow` function.
 */
export const useSessionWindow = (onWindowClose?: () => void) => {
  const [sessionWindow, setSessionWindow] = useState<Window | null>(null);

  /**
   * Opens a new session window with the given session ID and optional session code.
   */
  const openSessionWindow = (sessionId: string, sessionCode?: string) => {
    const url = `${routes.support_current_session}?session_id=${sessionId}${
      sessionCode ? `&code=${sessionCode}` : ''
    }`;
    const newWindow = window.open(url, '_blank', 'popup');

    // Attach event listener to handle cleanup on window close
    if (newWindow) {
      const handleBeforeUnload = () => {
        onWindowClose?.();
        setSessionWindow(null);

        newWindow.removeEventListener('beforeunload', handleBeforeUnload);
      };

			// Used to identify the window in the browser and close it if needed
      newWindow.name = `support-session-${sessionId}`;

      newWindow.addEventListener('beforeunload', handleBeforeUnload);
      setSessionWindow(newWindow);
    }
  };

  useEffect(() => {
    // Cleanup on component unmount or window close
    return () => {
      sessionWindow?.removeEventListener('beforeunload', () => {
        onWindowClose?.();
      });
    };
    // eslint-disable-next-line
  }, [sessionWindow]);

  return { openSessionWindow };
};

/**
 * Interceptor for closing the support session windows on error
 * @property onFulfilled - Axios response interceptor
 * @property onRejected - Axios error interceptor
*/
export const sessionWindowAxiosInterceptor =  {
  onFulfilled: (response: AxiosResponse<any, any>) => {
    return response;
  },
  onRejected: (error: any) => {
    const code = error.response?.data?.code;
    if (
      code === 'S0001' ||
      code === 'S0006' ||
      code === 'S0007' ||
      code === 'S9998'
    ) {
      if (
        window.opener &&
        window.name !== '' // The support session windows have an associated name
      ) {
        setTimeout(() => {
          window.close();
        }, 2000); // Close after 2 seconds
      }
    }
    return Promise.reject(error);
  }
}
