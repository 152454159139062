const acceslevel = {
  accesslevel: "Niveau d'accès",
  search_accesslevel: "Niveau d'accès à la recherche",
  add_accesslevel: "Nouveau niveau d'accès",
  update_accesslevel: "Niveau d'accès",
  accesslevel_manager: 'Gérer',
  doors: 'Des portes',
  users: 'Utilisateurs',
  schedules: 'Des horaires',
  can_not_delete: "Le niveau d'accès est protégé. Il ne peut pas être supprimé",
  extend_time: 'Prolonger le temps',
  override_inter: 'Annuler les verrouillages',
  form: {
    name: 'Nom',
    ocurrence: 'Occurrence',
    month: 'Mois',
    day: 'Jour',
    occurss: 'Se produire',
    year: 'An',
    duration_days: 'Durée Jours',
    hours: 'Les heures',
    minutes: 'Minutes',
    status: 'Statut',
    active: 'Actif',
    inactive: 'Inactif'
  },
  table: {
    state: 'ETAT',
    accesslevel: "NIVEAU D'ACCÈS",
    door: 'PORTE',
    schedules: 'PROGRAMME'
  },
  manager: {
    add_users: 'Ajouter des utilisateurs',
    search_users: 'Rechercher des utilisateurs',
    user_count: "Nombre d'utilisateurs",
    back_to_list: 'Retour à la liste',
    assign_accesslevel: "Attribuer un niveau d'accès",
    access_remove_success: "Niveau d'accès désassigné avec succès",
    access_remove_error: "Erreur lors de la désassignation du niveau d'accès",
    access_add_success: "Niveau d'accès attribué avec succès",
    access_add_error: "Erreur lors de l'attribution du niveau d'accès",
    remove_selected_btn: 'Désassigner à ({{count}}) sélectionné(s)',
    remove_selected_msg:
      'Êtes-vous sûr de vouloir désassigner {{accessLevelLabel}} des utilisateurs suivants ?',
    add_selected_btn: 'Attribuer à ({{count}}) sélectionné(s)',
    add_selected_msg:
      'Êtes-vous sûr de vouloir attribuer {{accessLevelLabel}} aux utilisateurs suivants ?',
    replace: 'Remplacer',
    replace_selected_btn: 'Remplacer à ({{count}}) sélectionné(s)',
    replace_success: "Remplacement du niveau d'accès réussi",
    replace_error: "Erreur lors du remplacement du niveau d'accès",
    replace_alevel_msg:
      'Êtes-vous sûr de vouloir remplacer {{accessLevelLabel}} par {{newAccessLevelLabel}} pour les utilisateurs suivants ?',
    count_users_selected: '{{count}} utilisateurs sélectionnés',
    users_with_errors:
      "Le niveau d'accès ne peut pas être attribué aux utilisateurs suivants :",
    alert: 'Alerte'
  }
};

export default acceslevel;
