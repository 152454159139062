const firmware = {
  header: 'Firmware',
  add_firmware: 'New Firmware',
  edit_firmware: 'Firmware',
  product_code: 'Product Code',
  version: 'Version',
  mandatory: 'Mandatory',
  launch: 'Launch',
  launched: 'Launched',
  not_launched: 'Not launched',
  notes: 'Release notes',
  add_success_msg: 'Firmware added successfully',
  update_success_msg: 'Firmware updated successfully',
  delete_msg:
    'Are you sure you want to delete the selected firmware? {{firmware}}',
  firmware_deleted: 'Firmware deleted',
  update_all: 'Update all the controllers',
  update_firmware: 'Update Firmware',
  upload_firmware: 'Upload Firmware',
  install_firmware: 'Install Firmware',
  select_firmware_msg: 'Select the firmware to install and press Continue',
  confirm_update_msg:
    'Are you sure you want to install firmware version {{version}} on Controller {{controller}}?',
  confirm_update_r_msg:
    'Are you sure you want to install firmware version {{version}} on Reader {{reader}}?',
  confirm_updateR_msg:
    'Are you sure you want to update the firmware for reader {{reader}}',
  firmware_error_title:
    'Firmware Error',
  table: {
    date: 'Date',
    controller: 'Controller',
    reader: 'Reader',
    release: 'Realease note',
    product_code: 'Product Code',
    version: 'Version',
    status: 'Status',
    launched: 'Launched',
    mandatory: 'Mandatory'
  }
};

export default firmware;
