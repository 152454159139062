const schedules = {
  title: 'Schedules',
  edit: 'Edit',
  delete: 'Delete',
  activate: 'Activate/Deactivate',
  add_schedule: 'New Schedule',
  edit_schedule: 'Schedule',
  search_schedules: 'Search Schedules',
  cancel: 'Cancel',
  save: 'Save',
  update: 'Update',
  schedule_basic: 'Basic',
  schedule_advanced: 'Advanced',
  cycle_length: 'Cycle Length ({{days}} days)',
  days_label: 'Days of week',
  dow: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  name: 'Name',
  cycle_date: 'Cycle Start Date',
  default_start_time: 'Default Start Time',
  default_end_time: 'Default End Time',
  date: 'Date',
  startTime: 'Start Time',
  endTime: 'End Time',
  today: 'Today',
  month: 'Month',
  week: 'Week',
  goToDate: 'Go to start date',
  custom: 'Custom',
  cycle_length_text: 'Cycle Length',
  custom_modal_msg: 'Select the amount of days in the cycle (max 28)',
  confirmation_msg: 'Are you sure you want to change cycle length? Current configuration will be lost',
  date_confirmation_msg: 'Are you sure you want to change the cycle start date? Current configuration will be lost',
  edit_event: 'Edit Event',
  holidays: {
    include: 'Include',
    exclude: 'Exclude',
    drag_and_drop: 'Drag and Drop a holiday to include or exclude',
    holidays: 'Holidays',
    add_new: 'Add New',
    INCLUDE: 'INCLUDE',
    EXCLUDE: 'EXCLUDE',
    include_description: 'The duration of the holiday will be ADDED to the schedule',
    exlude_description: 'The duration of the holiday will be REMOVED from the schedule'
  },
  table: {
    schedule_id: 'ID',
    schedule_label: 'SCHEDULES',
    cycle_length_label: 'CYCLE LENGTH',
    startdate_label: 'START DATE',
    active_label: 'STATE'
  },
  error: {
    hour_format: 'Format must be hh:mm AM/PM',
    start_time: 'Start time cannot be after end time',
    end_time: 'End time cannot be before start time',
  }
};

export default schedules;
