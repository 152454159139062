const menu = {
  header: 'Menu List',
  add_menu: 'New Menu',
  search_menu: 'Search Menu',
  submit: 'Submit',
  cancel: 'Cancel',
  update_menu: 'Menu',
  success_update: 'Update Success',
  success_add: 'New Menu added successfully',
  form: {
    name: 'Name',
    description: 'Description',
    path: 'Path',
    order: 'Order',
    roles_permission: 'Roles Permission',
    menu_list: 'Menu List',
    none: 'None',
    icon: 'Icon'
  }
};

export default menu;
