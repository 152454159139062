const permissions = {
  ALL_ACCESS: 'Todos los derechos',
  NO_ACCESS: 'Sin acceso',
  delete: 'Eliminar',
  EXECUTE: 'Ejecutar',
  update_permission: 'Permiso',
  update: 'Actualizar',
  create_permission: 'Nuevo Permiso',
  create: 'Crear',
  header: 'Lista de permisos',
  add_permission: 'Agregar permiso',
  search_permission: 'Buscar permiso',
  action: 'Acción',
  enable: 'Habilitar',
  confirm_update_message:
    'Realizar cambios puede afectar el funcionamiento de la aplicación. ¿Estás seguro de que deseas continuar?',
  confirm_modal_cancel: 'Cancelar',
  confirm_modal_confirm: 'Actualizar',
  more: 'Más...',
  table: {
    name: 'NOMBRE',
    description: 'DESCRIPCIÓN',
    active: 'ACTIVO'
  },
  form: {
    description: 'Descripción',
    label: 'Etiqueta',
    sectag: 'Etiqueta de sección'
  },
  view: 'Ver',
  VIEW: 'Ver',
  View: 'Ver',
  ADD: 'Agregar',
  Add: 'Agregar',
  EDIT: 'Editar',
  Edit: 'Editar',
  DELETE: 'Eliminar',
  Delete: 'Eliminar',
  UPDATE: 'Actualizar',
  Update: 'Actualizar',
  SUBSCRIPTION: 'Suscripción',
  Subscription: 'Suscripción',
  SUBSCRIPTIONMANAGEMENT: 'Gestión de suscripciones',
  Subscriptionmanagement: 'Gestión de suscripciones',
  USERMANAGEMENT: 'Gestión de usuarios',
  Usermanagement: 'Gestión de usuarios',
  EMPLOYEEMANAGEMENT: 'Gestión de empleados',
  Employeemanagement: 'Gestión de empleados',
  LOCKDOWN: 'Bloqueo',
  Lockdown: 'Bloqueo',
  ACTIVATION: 'Activación',
  Activation: 'Activación',
  REPLACE: 'Reemplazar',
  Replace: 'Reemplazar',
  REMOVE: 'Eliminar',
  Remove: 'Eliminar',
  REFRESH: 'Actualizar',
  Refresh: 'Actualizar',
  RESTORE: 'Restaurar',
  Restore: 'Restaurar',
  RESTORETAMPER: 'Restaurar manipulación',
  Restoretamper: 'Restaurar manipulación',
  EDITBASIC: 'Editar básico',
  Editbasic: 'Editar básico',
  EDITADVANCED: 'Editar avanzado',
  Editadvanced: 'Editar avanzado',
  LOCK: 'Bloquear',
  Lock: 'Bloquear',
  ACCESS: 'Acceso',
  Access: 'Acceso',
  LOCKDOWNACCESS: 'Acceso de bloqueo',
  Lockdownaccess: 'Acceso de bloqueo',
  START: 'Iniciar',
  Start: 'Iniciar',
  STOP: 'Detener',
  Stop: 'Detener',
  NOTIFICATION: 'Notificación',
  Notification: 'Notificación',
  LOGIN: 'Iniciar sesión',
  Login: 'Iniciar sesión',
  CARD: 'Tarjeta',
  Card: 'Tarjeta',
  PROFILE: 'Perfil',
  Profile: 'Perfil',
  MANAGEMENT: 'Gestión',
  Management: 'Gestión',
  EMPLOYEE: 'Empleado',
  Employee: 'Empleado',
  Save: 'Guardar',
  SAVE: 'Guardar',
  Manager: 'Gestionar',
  MANAGER: 'Gestionar',
  Level2: 'Nivel 2',
  LEVEL2: 'Nivel 2'
};

export default permissions;
