const general = {
  controller: 'Controller',
  add_controller: 'New Controller',
  edit_controller: 'Controller',
  search_controller: 'Search Controller',
  search_serial_number: 'Search Serial Number',
  register_msg:
    'Connect the first controller to the network, fill out the serial number and press register to continue',
  serial_number: 'Serial Number',
  new_serial_number: 'New Serial Number',
  site: 'Site',
  register: 'Register',
  setup: 'Setup',
  doors: 'Doors',
  doors_activation: 'Doors Activation',
  finish: 'Finish',
  wizard: 'Wizard',
  run_wizard: 'Run Wizard',
  reboot: 'Reboot',
  controller_wizard: 'Site Wizard',
  access_level: 'Access Level',
  user_cards: 'User & Credentials',
  remove_controller_msg: 'Do yo want to remove the controller',
  enable_door: 'Are you sure you want to Enable the door {{door}}?',
  disable_door: 'Are you sure you want to Disable the door',
  confirm_selected_controller:
    'Are you sure you want to register this controller {{controller}} to site {{site}}',
  confirm_delete_firmware_available:
    'Are you sure you want to remove firmware version {{version}}',
  choose_controller_msg: 'Choose a controller and click to register',
  processing_controller: 'Processing the new controller on the network.',
  reboot_message: 'Rebooting Controller. Please wait 60 seconds:',
  door_info_message: 'Retrieving door information. Please wait 60 seconds:',
  no_door_site_msg: 'No doors to display',
  no_accesslvl_site_msg: 'No access levels to display',
  open_wizard_msg: 'Do you want to open the site Wizard?',
  form: {
    enable_ip: 'Enable IP Configuration',
    dns: 'IP DNS',
    use_dhcp: 'Use DHCP',
    subnet_mask: 'Subnet Mask',
    ip_address: 'IP Address',
    webport: 'Web port',
    username: 'Username',
    password: 'Password',
    name: 'Name',
    address: 'Address',
    country: 'Country',
    city: 'City',
    state: 'State',
    zip_code: 'Zipcode',
    timezone: 'Timezone',
    ip_format_error: 'Wrong format (xxx.xxx.xxx.xxx)',
    mac_address: 'MAC Address',
    gateway: 'Gateway',
    serial_number: 'Serial Number',
    setup_error:
      'There was an error setting up the controller, please try again',
    showTampered: 'Show Tamper options',
    tabControllerData: 'Controller Data',
    tabControllerTamper: 'Tamper'
  },
  table: {
    name: 'NAME',
    address: 'ADDRESS',
    country: 'COUNTRY',
    city: 'CITY',
    state: 'STATE',
    zipcode: 'ZIPCODE',
    timezone: 'TIMEZONE'
  },
  register_steps: 'Step {{step}} / 6',
  register_steps_alt: 'Step {{step}} / 7',
  replace_steps: 'Step {{step}} / 30',
  steps: {
    step1: 'Ping controller',
    step2: 'Retrieve registration code',
    step3: 'Assign to cloud site',
    step4: 'Generating credentials',
    step5: 'Testing credentials',
    step6: 'Connected!',
    step7: 'Attaching to the site'
  },
  online: 'Online',
  offline: 'Offline',
  available_doors: '{{doors}} doors available',
  register_error: 'There was an error on one of the steps, please try again',
  errors: {
    ping: 'There was an error pinging the controller, please check the serial number',
    verification_code:
      'There was an error retrieving the verification code, please check the controller connection',
    assign:
      'There was an error assigning the controller to the site, please try again',
    timeout: 'Your session has expired due to inactivity, please try again'
  },
  ping_successful: 'Controller ping successful',
  ping_unsuccessful: 'Controller ping unsuccessful',
  to_continue: 'To continue',
  ping_successful_msg: 'Click the following link to get the verification code',
  verification_code: 'Fill the verification code here',
  back: 'Back',
  assign: 'Assign',
  ping_verify: 'Ping to {{serial_number}} successful, controller is alive',
  ping_verify_fail:
    'No response received from controller {{serial_number}}, the controller is offline',
  tamperedTitle: '(Tampered / Tampered Alert)',
  buttonRestore: 'Restore',
  tampered: 'Tampered',
  alarmed: 'Alarmed',
  tamperOptions: 'Tamper Options',
  tamperedAlarm: 'Tamper alarm',
  tamperedNormal: 'Normal',
  tamperedEnableCheck: 'Enable',
  tamperedAlarmCheck: 'Alarm',
  tamperedRestoreSuccess: 'Controller alarm restored successfully',
  readers: 'Readers',
  tamperUpdateSuccess: 'Tamper options updated successfully',
  readersTab: {
    editInterfaceMode: 'Edit Interface Mode',
    localBus: 'Local Bus',
    reader1: 'Reader 1',
    reader2: 'Reader 2',
    cancel: 'Cancel',
    save: 'Save',
    readerPort: 'Reader Port',
    EditReaderModal: {
      editReader: 'Edit Reader',
      displayName: 'Display Name',
      productCode: 'Product Code',
      port: 'Port',
      serialNumber: 'Serial Number',
      firmware: 'Firmware'
    }
  },
  sending: 'Sending',
  receiving: 'Receiving',
  select_controller_replace: 'Select a controller to replace',
  process_irreversible:
    'This process is irreversible <br/> You can close this window and return at any moment.',
  confirmation_replace_msg:
    'Are you sure you want to replace the controller {{serialToReplace}} with this new serial {{serialNumber}}',
  press_done_msg:
    'Replacement has been finished, press <b>Done</b> to close the window',
  confirm_wizard_closed: 'Are you sure you want to close Site Wizard?',
  confirmation_door_msg:
    'Are you sure you want to {{dooorAction}} the {{doorStatus}} door?',
  confirmation_reboot_msg:
    'Are you sure you want to reboot controller {{controller}}?',
  replace_action: {
    validations: 'General validations',
    gencreds: 'Generating credentials',
    testcreds: 'Testing credentials',
    connected: 'Controller connected',
    ismain: 'Controller is the main controller for this Site',
    modulerestartbegin: 'The controller is restarting',
    modulerestartend: 'The controller is online',
    getrectableinfo: 'Gathering entities information',
    getdoor: 'Gathering door information',
    getdoorlock: 'Gathering doorlock information',
    getinputlogic: 'Gathering input logic information',
    getoutput: 'Gathering output information',
    getreader: 'Gathering reader information',
    getmodtable: 'Gathering modules information',
    getipconn: 'Gathering IP configuration',
    setrtc: 'Setting timezone configuration',
    setsubcontroller: 'Setting subcontrollers',
    setglobalsite: 'Setting global site',
    setdesfireapp: 'Setting Desfire App ID Master',
    setreadermode: 'Setting readers into autoprogramming mode',
    setholiday: 'Set holidays',
    setschedule: 'Set schedules',
    setaccesslevel: 'Set access levels',
    setdoor: 'Set doors',
    setfactorydefault: 'Set factory default on old controller',
    removecontroller: 'Remove old controller from site',
    finished: 'Replacement finished'
  },
  view_syncing: 'View syncing process',
  syncing: 'Syncing',
  synced: 'synced',
  enabling: 'Enabling',
  enabled: 'enabled',
  sync: {
    ctrl: ' Controller setup configuration gathered',
    door: ' Doors gathered',
    output: ' Outputs gathered',
    reader: ' Readers gathered',
    input: ' Input Logic gathered',
    doorlock: ' DoorLocks gathered',
    holiday: ' Holidays gathered',
    schedule: ' Schedules gathered',
    al: ' Access Levels gathered',
    tamper: ' Tampers Cfg gathered',
    timezone: ' Set timezone',
    global_site: ' Set global site',
    krypto: ' Krypto security enabled',
    set_reader: ' Setting readers into autoprogramming mode',
    lockdown: ' Setting Lockdown',
    set_holiday: ' Set holidays',
    set_schedule: ' Set schedules',
    set_al: ' Set access levels',
    set_user: ' Set users',
    set_card: ' Set credentials',
    synced: ' Synced'
  },
  synced_msg: {
    reader_synced: 'Readers',
    io_synced: 'Inputs/Outputs',
    al_synced: 'Access level',
    tz_synced: 'Timezone',
    krypto: 'Krypto security',
    user_card_synced: 'Users and credentials'
  },
  atrium_unavailable_msg:
    'There is not any controller connected at this moment, try when at least one of your controllers is online.',
  door_activation: 'Door activation'
};

export default general;
