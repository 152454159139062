const acceslevel = {
  accesslevel: 'Nivel de acceso',
  search_accesslevel: 'Buscar nivel de acceso',
  add_accesslevel: 'Nuevo Nivel de Acceso',
  update_accesslevel: 'Nivel de Acceso',
  accesslevel_manager: 'Gestionar',
  doors: 'Puertas',
  users: 'Usuarios',
  schedules: 'Horarios',
  can_not_delete: 'El nivel de acceso está protegido. no se puede borrar',
  extend_time: 'Extender tiempo',
  override_inter: 'Anular enclavamientos',
  form: {
    name: 'Nombre',
    ocurrence: 'Ocurrencia',
    month: 'Mes',
    day: 'Día',
    occurss: 'Ocurrir',
    year: 'Año',
    duration_days: 'Duración (Días',
    hours: 'Horas',
    minutes: 'Minutos',
    status: 'Estatus',
    active: 'Activo',
    inactive: 'Inactivo'
  },
  table: {
    state: 'EXPRESAR',
    accesslevel: 'NIVEL DE ACCESO',
    door: 'PUERTA',
    schedules: 'CALENDARIO'
  },
  manager: {
    add_users: 'Agregar usuarios',
    search_users: 'Buscar usuarios',
    user_count: 'Cantidad de usuarios',
    back_to_list: 'Volver a la lista',
    assign_accesslevel: 'Asignar nivel de acceso',
    access_remove_success: 'Nivel de acceso desasignado con éxito',
    access_remove_error: 'Error al desasignar nivel de acceso',
    access_add_success: 'Nivel de acceso asignado con éxito',
    access_add_error: 'Error al asignar nivel de acceso',
    remove_selected_btn: 'Desasignar a ({{count}}) seleccionados',
    remove_selected_msg:
      '¿Está seguro de que desea desasignar {{accessLevelLabel}} de los siguientes usuarios?',
    add_selected_btn: 'Asignar a ({{count}}) seleccionados',
    add_selected_msg:
      '¿Está seguro de que desea asignar {{accessLevelLabel}} a los siguientes usuarios?',
    replace: 'Reemplazar',
    replace_selected_btn: 'Reemplazar a ({{count}}) seleccionados',
    replace_success: 'Nivel de acceso reemplazado con éxito',
    replace_error: 'Error al reemplazar nivel de acceso',
    replace_alevel_msg:
      '¿Está seguro de que desea reemplazar {{accessLevelLabel}} con {{newAccessLevelLabel}} para los siguientes usuarios?',
    count_users_selected: '{{count}} usuarios seleccionados',
    users_with_errors:
      'El nivel de acceso no se puede asignar a los siguientes usuarios:',
    alert: 'Alerta'
  }
};

export default acceslevel;
