export const TEMPORAL_IMAGE_BYTE_LIMIT = 1048576; // 1MB

export enum EntityType {
  OBJ_TYPE_CONTROLLER = -1,
  OBJ_TYPE_OUTPUT = 0,
  OBJ_TYPE_READER = 1,
  OBJ_TYPE_RELAY = 2,
  OBJ_TYPE_BELL = 3,
  OBJ_TYPE_DOOR = 4,
  OBJ_TYPE_MODULE_TABLE = 5,
  OBJ_TYPE_ZONE = 6,
  OBJ_TYPE_KEYPAD = 7,
  OBJ_TYPE_MACRO = 8,
  OBJ_TYPE_INPUT = 9,
  OBJ_TYPE_PARTITION = 10,
  OBJ_TYPE_USER = 11,
  OBJ_TYPE_CARD = 12,
  OBJ_TYPE_USER_CODE = 13,
  OBJ_TYPE_SYSTEM = 14,
  OBJ_TYPE_RESSOURCE = 15,
  OBJ_TYPE_ACCESS = 16,
  OBJ_TYPE_EVENT_SAVE = 17,
  OBJ_TYPE_RTC = 18,
  // OBJ_TYPE_FREE= 19,
  OBJ_TYPE_RECORD_TBL = 20,
  OBJ_TYPE_INPUT_HARDWARE = 21,
  OBJ_TYPE_SCHED = 22,
  OBJ_TYPE_HOLIDAY = 23,
  OBJ_TYPE_ACC_LEVEL = 24,
  OBJ_TYPE_FIRMWARE = 25,
  OBJ_TYPE_ZONE_OPTION_TEMPLATE = 26,
  OBJ_TYPE_REPORTING_CENTRAL = 27,
  OBJ_TYPE_IPCONNECTION = 28,
  OBJ_TYPE_2WIRE_SMOKE = 29,
  OBJ_TYPE_LEARNING_MODE = 30,
  OBJ_TYPE_SYSTEM_LOG = 31,
  OBJ_TYPE_OUTPUT_PWM = 32,
  OBJ_TYPE_KEYPAD_INTERNAL = 33,
  OBJ_TYPE_HARDWARE = 34,
  OBJ_TYPE_PNP_MASTER = 35,
  OBJ_TYPE_PNP_SLAVE = 36,
  OBJ_TYPE_DOOR_LOCK = 37,
  OBJ_TYPE_USER_LOGIN = 38,
  OBJ_TYPE_HW_TRBL_NOT_USED = 39, // Not used but defined in software
  OBJ_TYPE_MODULE = 40,
  OBJ_TYPE_SELFTEST = 41,
  OBJ_TYPE_BATT = 42,
  OBJ_TYPE_AUX = 43,
  OBJ_TYPE_AC = 44,
  OBJ_TYPE_TAMPER = 45,
  OBJ_TYPE_RECORD = 46,
  OBJ_TYPE_PULL_STATION = 47,
  OBJ_TYPE_DISTRIBUTION = 48,
  OBJ_TYPE_LED_STATUS = 49,
  OBJ_TYPE_TACT_SWITCH = 50,
  OBJ_TYPE_WEBPAGE = 51,
  OBJ_TYPE_ACCELEROMETER = 52,
  OBJ_TYPE_LCD_DISPLAY = 53,
  OBJ_TYPE_TEMPERATURE_SENSOR = 54,
  OBJ_TYPE_EXPANSION_PORT = 55,
  OBJ_TYPE_OS = 56,
  OBJ_TYPE_DIALER = 57,
  OBJ_TYPE_IPCOM_CLIENT = 58,
  OBJ_TYPE_SLAVE_POWER = 59,
  OBJ_TYPE_GLB_ACCESS_LEVEL = 60,
  OBJ_TYPE_SYNC = 61,
  OBJ_TYPE_WATCHDOG = 62,
  OBJ_TYPE_DOOR_STATUS_SYNC = 63,
  OBJ_TYPE_INTERLOCK = 64,
  OBJ_TYPE_IR_PIM = 65,
  OBJ_TYPE_IR_APM = 66,
  OBJ_TYPE_SERIAL_PORT_CONFIG = 67,
  OBJ_TYPE_ETHERNET_CONFIGURATION = 68,
  OBJ_TYPE_EMAIL = 69,
  OBJ_TYPE_EMAIL_SETTINGS = 70,
  OBJ_TYPE_ANTIPASSBACK = 71,
  OBJ_TYPE_CAMERA = 72,
  OBJ_TYPE_MASTER_CTRL = 73,
  OBJ_TYPE_HTTPS = 74,
  OBJ_TYPE_SSL_CERTIFICATE = 75,
  OBJ_TYPE_SDK = 76,
  OBJ_TYPE_VLOCK = 77,
  OBJ_TYPE_MACRO_TIMER = 78,
  OBJ_TYPE_MACRO_COUNTER = 79,
  OBJ_TYPE_NET_WDG = 80,
  OBJ_TYPE_ELEVATOR = 81,
  OBJ_TYPE_DOOR_OFFLINE_MODE = 82,
  OBJ_TYPE_FLOOR = 83,
  OBJ_TYPE_FLOOR_LEVEL = 84,
  OBJ_TYPE_EMAIL_MUSTERING = 85,
  OBJ_TYPE_AA_HUB = 86,
  OBJ_TYPE_AA_DOOR_HANDLE = 87,
  OBJ_TYPE_LICENSE = 88,
  OBJ_TYPE_LOCKDOWN = 89,
  OBJ_TYPE_EBUS_MODULE = 90,
  OBJ_TYPE_TEXACOM = 91,
  OBJ_TYPE_TEXECOM_CLIENT = 92,
  OBJ_TYPE_TEXECOM_SERVER = 93,
  OBJ_TYPE_CUSTOM_DFLT = 94,
  OBJ_TYPE_DISTRIBUTION_LIVE = 95, // Used by record manager to generate live event (not saved) on record write
  OBJ_TYPE_INTERFACE_RS485 = 96,
  OBJ_TYPE_DESFIRE_AID_MST = 97,
  OBJ_TYPE_DESFIRE_AID_SLV = 98,
  OBJ_TYPE_RFLF = 99,
  OBJ_TYPE_SITE_SETTING = 100,
  OBJ_TYPE_ATRIUM_FINDER = 101,
  OBJ_TYPE_FW_DISPATCH = 102,
  OBJ_TYPE_ACC_LEVEL_GROUP = 120,
  OBJ_TYPE_ACC_LEVEL_GLOBAL = 121,
  OBJ_TYPE_FLOOR_PLAN = 122,
  OBJ_TYPE_SYNC_AREA = 123,
  OBJ_TYPE_RF_REMOTE = 124,
  OBJ_TYPE_RF_REMOTE_BTN_TEMPLATE = 125,
  OBJ_TYPE_RF_UNIVERSAL_RCV = 126,
  OBJ_TYPE_COAP_BRIDGE = 127,
  OBJ_TYPE_COAP_SERVER = 128,
  OBJ_TYPE_BLE = 129,
  OBJ_TYPE_RESTORE = 130,
  OBJ_TYPE_MOBILE_PASS = 131,
  OBJ_TYPE_DIP_SWITCH = 132,
  OBJ_TYPE_INPUT_CONTACT = 133, // special type for input PNP
  OBJ_TYPE_INPUT_REX = 134, // special type for input PNP
  OBJ_TYPE_NTP_CLIENT = 135,
  OBJ_TYPE_CENTAUR_TO_ATRIUM = 200,
  OBJ_TYPE_COMMAND_GROUP = 201,
  OBJ_TYPE_EVT_EXTRA_CMD = 202,
  OBJ_TYPE_OPERATOR = 203,
  OBJ_TYPE_HOLIDAY_GROUP = 204,
  OBJ_TYPE_CENTAUR_SVR = 205,
  OBJ_TYPE_EVT_EXTRA_DELAY = 206,
  OBJ_TYPE_EVT_EXTRA_COUNTER = 207,
  OBJ_TYPE_EVT_EXTRA_DOOR_SIDE = 208,
  OBJ_TYPE_EVT_EXTRA_CARD_NUMBER_HIGH = 209,
  OBJ_TYPE_EVT_EXTRA_CARD_NUMBER_LOW = 210,
  OBJ_TYPE_EVT_EXTRA_CARD_FORMAT = 211,
  OBJ_TYPE_EVT_EXTRA_IP_ADDR = 212,
  OBJ_TYPE_REX = 213,
  OBJ_TYPE_USB_MASTER = 214,
  OBJ_TYPE_MEMORY_STATUS = 215,
  OBJ_TYPE_WEB_TLS = 216,
  OBJ_TYPE_EVT_EXTRA_SERIAL = 217,
  OBJ_TYPE_EVT_EXTRA_RF_RSSI = 218,
  OBJ_TYPE_EVT_EXTRA_RF_TYPE_INFO = 219,
  OBJ_TYPE_EVT_EXTRA_RF_KEY = 220,
  OBJ_TYPE_EVT_EXTRA_RF_ROLLING_CNT = 221,
  OBJ_TYPE_EVT_EXTRA_MDL_ROLE = 222,
  OBJ_TYPE_EVT_EXTRA_MDL_MODE = 223,
  OBJ_TYPE_EVT_EXTRA_ITF_MODE = 224,
  OBJ_TYPE_ITF_MDL = 225,
  OBJ_TYPE_EVT_EXTRA_BAUDRATE = 226,
  OBJ_TYPE_ITF_NAT = 227,
  OBJ_TYPE_EVT_EXTRA_CARD_UID_HIGH = 228,
  OBJ_TYPE_EVT_EXTRA_CARD_UID_LOW = 229,
  OBJ_TYPE_EVT_EXTRA_TIME_STAMP = 230,
  OBJ_TYPE_EVT_EXTRA_DOUBLE_SWIPE = 231,
  OBJ_TYPE_EVT_EXTRA_CODE = 232,
  OBJ_TYPE_EVT_EXTRA_CARD = 233,
  OBJ_TYPE_EVT_EXTRA_SOFTWARE = 234,
  OBJ_TYPE_CLOUD_SENDER = 137
}
