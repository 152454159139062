import { createContext, useContext, useEffect, useState } from 'react';

import { getHelpLinks } from 'api/api';

export type HelpLinks = {
  login_help_link: string;
  dashboard_help_link: string;
};

type HelpLinksContextType = {
  links: HelpLinks;
  setLinks: React.Dispatch<React.SetStateAction<HelpLinks>>;
};

const HelpLinksContext = createContext<HelpLinksContextType | undefined>(undefined);

export const HelpLinksProvider = ({ children }: React.PropsWithChildren) => {
  const [links, setLinks] = useState<HelpLinks>({
    login_help_link: '',
    dashboard_help_link: ''
  });

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const { login_help_link, dashboard_help_link } = await getHelpLinks();
        setLinks({
          login_help_link,
          dashboard_help_link
        });
      } catch (error: any) {
        console.error('Error fetching help links', error);
      }
    };

    fetchLinks();
  }, []);

  return (
    <HelpLinksContext.Provider value={{ links, setLinks }}>
      {children}
    </HelpLinksContext.Provider>
  );
};

export const useHelpLinks = () => {
  const context = useContext(HelpLinksContext);
  if (context === undefined) {
    throw new Error('useHelpLinks must be used within a HelpLinksProvider');
  }
  return context;
};
