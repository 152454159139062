const acceslevel = {
  accesslevel: 'Access Level',
  search_accesslevel: 'Search Access Level',
  add_accesslevel: 'New Access Level',
  update_accesslevel: 'Access Level',
  accesslevel_manager: 'Manage',
  doors: 'Doors',
  users: 'Users',
  schedules: 'Schedules',
  can_not_delete: "The access level is protected. It can't be deleted",
  extend_time: 'Extend time',
  override_inter: 'Override Interlocks',
  form: {
    name: 'Name',
    ocurrence: 'Occurrence',
    month: 'Month',
    day: 'Day',
    occurss: 'Occurs',
    year: 'Year',
    duration_days: 'Duration Days',
    hours: 'Hours',
    minutes: 'Minutes',
    status: 'Status',
    active: 'Active',
    inactive: 'Inactive'
  },
  table: {
    state: 'STATE',
    accesslevel: 'ACCESS LEVEL',
    door: 'DOOR',
    schedules: 'SCHEDULE'
  },
  manager: {
    add_users: 'Add Users',
    search_users: 'Search Users',
    user_count: 'User count',
    back_to_list: 'Back to list',
    assign_accesslevel: 'Assign Access Level',
    access_remove_success: 'Access level unassigned successfully',
    access_remove_error: 'Error unassigning access level',
    access_add_success: 'Access level assigned successfully',
    access_add_error: 'Error assigning access level',
    remove_selected_btn: 'Unassign to ({{count}}) selected',
    remove_selected_msg:
      'Are you sure you want to unassign {{accessLevelLabel}} from the following user(s)?',
    add_selected_btn: 'Assign to ({{count}}) selected',
    add_selected_msg:
      'Are you sure you want to assign {{accessLevelLabel}} to the following user(s)?',
    replace: 'Replace',
    replace_selected_btn: 'Replace to ({{count}}) selected',
    replace_success: 'Replace access level successful',
    replace_error: 'Error replacing access level',
    replace_alevel_msg:
      'Are you sure you want to replace {{accessLevelLabel}} with {{newAccessLevelLabel}} for the following user(s)?',
    count_users_selected: '{{count}} users selected',
    users_with_errors:
      'The access level cannot be assigned to the following user(s):',
    alert: 'Alert'
  }
};

export default acceslevel;
