const dealerRequests = {
  header: 'Liste des Concessionnaires',
  dealer_requests: 'Demandes des Concessionnaires',
  search_dealers: 'Rechercher des Revendeurs',
  confirm_modal_cancel: 'Non, annuler',
  confirm_modal_confirm: 'Oui, supprimer',
  request_pending: 'Demandes en attente',
  approved: 'Approuvé',
  pending: 'En Attente',
  denied: 'Refusé',
  in_review: 'En Revue',
  approve: 'Approuver',
  deny: 'Refuser',
  review: 'Revue',
  close: 'Fermer',
  all: 'Tout',
  submit: 'Soumettre',
  cancel: 'Annuler',
  update: 'Mise à jour',
  edit: 'Modifier',
  profile: 'Profil',
  history: 'Histoire',
  email_notifications: 'Notifications par e-mail',
  email_modal_description: 'Cette liste d\'emails recevra la candidature du revendeur',
  table: {
    company: 'ENTREPRISE',
    full_name: 'NOM ET PRÉNOM',
    email: 'EMAIL',
    country: 'PAYS',
    date: 'DATE',
    status: 'STATUT'
  }
};

export default dealerRequests;
