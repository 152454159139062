const general = {
  new: 'Nouveau',
  active: 'Actif',
  inactive: 'Inactif',
  yes: 'Oui',
  no: 'No',
  day: 'Jour',
  days: 'Jours',
  hour: 'Heure',
  minute: 'Minute',
  second: 'Deuxième',
  the: 'La',
  yearly: 'Annuel',
  on: 'Sur',
  and: 'Et',
  welcome: 'Bienvenu',
  online: 'En ligne',
  offline: 'Hors ligne',
  all_sites: 'Tous les sites',
  refresh: 'Rafraîchir',
  success: 'Succès',
  error: 'Erreur',
  time_out: 'Temps libre',
  kprog_error: 'Kprog Erreur',
  delete_message: 'Etes-vous sûr de vouloir supprimer',
  replacing: 'Redémarrage',
  rebooting: 'Rebooting',
  from: 'Depuis',
  to: 'Pour',
  general: 'Général',
  confirmation: 'Confirmation',
  filters: 'Filtres',
  results: 'Résultats',
  loading: 'Le bourrage',
  features: 'Características',
  coming_soon: 'À venir',
  reason: 'Raison',
  alerts: 'Alertes',
  multisite: 'Multisite',
  comments: 'Commentaires',
  leave_message:
    'Êtes-vous sûr de vouloir quitter? Toutes les modifications non enregistrées seront perdues.',
  date_time: 'Date - Heure',
  quantity: 'Quantité',
  status: 'Statut',
  summary: 'Résumé',
  more_filters: 'Plus de filtres',
  invalid_date: 'Date invalide',
  field_required: 'Ce champ est obligatoire',
  date_after: 'La date doit être à ou après {{date}}',
  date_before: 'La date doit être à ou avant {{date}}',
  file_too_big: 'Le fichier est trop volumineux ! Veuillez sélectionner un fichier inférieur à 1 Mo',
  btn: {
    launch: 'Lancement',
    discontinue: 'Cesser',
    continue: 'Continuer',
    back: 'Retour',
    open: 'Ouverte',
    new: 'Nouveau',
    select: 'Sélectionner',
    selected: 'Choisi',
    view: 'Voir',
    delete: 'Effacer',
    update: 'Mise à jour',
    edit: 'Éditer',
    submit: 'Soumettre',
    confirm: 'Confirmer',
    next: 'Prochain',
    previous: 'Précédent',
    save: 'Sauvegarder',
    checkout: 'Vérifier',
    activate: 'Activer',
    activated: 'Activé',
    inactivate: 'Désactiver',
    inactivated: 'Désactivé',
    finish: 'Finir',
    add_card: 'Ajouter\'d',
    cancel: 'Annuler',
    close: 'Fermer',
    dismiss: 'Rejeter',
    add_user: 'Ajouter \'r',
    edit_user: 'Modifier l\'utilisateur',
    enable: 'Activer',
    disable: 'Désactiver',
    done: 'Fait',
    replace: 'Remplacer',
    remove: 'Retirer',
    reset: 'Réinitialiser à l\'état normal',
    firmware: 'Micrologiciel',
    lock: 'Verrouillage (annulation)',
    unlock: 'Déverrouiller (remplacement)',
    grant_access: 'Accorder l\'accès',
    grant_extend: 'Accès étendu',
    actions: 'Actions',
    enabled: 'Activé',
    disabled: 'Désactivée',
    enable_access: 'Activé accéder',
    disable_access: 'Désactivée accéder',
    access: 'Accéder',
    unassigned: 'Non Attribué',
    assigned: 'Attribué',
    register: 'S\'inscrire',
    change: 'Monnaie',
    refresh: 'Rafraîchir',
    restore: 'Restaurer',
    resend_mail: 'Ré-envoyer l\'email',
    send: 'Envoyer',
    skip: 'Sauter',
    test: 'Test',
    list_view: 'Vue en liste',
    chart_view: 'Vue graphique',
    default: 'Défaut',
    sign_in: 'Se Connecter',
    go_back: 'Retourner',
    now: 'Maintenant',
    later: 'Plus tard',
    resend: 'Renvoyer',
    add: 'Ajouter',
    reset_position: 'Réinitialiser la position',
    ok: 'OK',
    clear: 'Clair',
    apply: 'Appliquer'
  },
  form: {
    timezone: 'Fuseau horaire'
  },
  month: {
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Peut',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre'
  },
  day_name: {
    sunday: 'Dimanche',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi'
  },
  cardinal: {
    first: 'Premier',
    second: 'Deuxième',
    third: 'Troisième',
    fourth: 'Quatrième',
    last: 'Dernier'
  },
  date_filter: {
    any: 'N\'importe quel',
    today: 'Aujourd\'hui',
    yesterday: 'Hier',
    this_week: 'Cette semaine',
    last_week: 'La semaine dernière',
    this_month: 'Ce mois-ci',
    last_month: 'Le mois dernier',
    this_year: 'Cette année',
    last_7_days: 'Les 7 derniers jours',
    last_30_days: 'Les 30 derniers jours',
    last_60_days: 'Les 60 derniers jours',
    last_90_days: 'Les 90 derniers jours',
    last_180_days: 'Les 180 derniers jours',
    custom: 'Personnalisé'
}
};

export default general;
