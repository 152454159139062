const general = {
  sites: 'Sites',
  site: 'Site',
  door: 'Door',
  add_site: 'New Site',
  edit_site: 'Site',
  edit_site_access: 'Edit Site Access',
  search_site: 'Search Site',
  copy_organization: 'Copy Account',
  delete_site_message: 'Are you sure you want to delete {{site}}?',
  notifications: 'Notifications',
  notification: 'Notification',
  events: 'Events',
  site_info: 'Site Information',
  total_sites_assigned: 'Total sites assigned',
  alerts: 'Alerts',
  add_notification: 'New Notification',
  send_to: 'Send to',
  delete_contact_message: 'Are you sure you want to delete {{contact}} notification contact?',
  invalid_email: 'Invalid email format',
  select_unselect_all: 'Select/Unselect All',
  notification_at_least_one: 'Select at least one notification',
  form: {
    name: 'Name',
    address: 'Address',
    country: 'Country',
    city: 'City',
    state: 'State',
    zip_code: 'Zipcode',
    timezone: 'Timezone',
    contact_to: 'Contact to',
    type_email: 'Type email',
    send_notification: 'Send notification',
    lockdown: 'Lockdown (started, stopped)',
    alarm_forced: 'Door in alarm forced',
    alarm_tampared: 'Door in alarm tampered',
    tamper: 'Tamper',
    battery_troubles: 'Battery troubles',
    power_troubles: 'Power troubles'
  },
  table: {
    name: 'NAME',
    address: 'ADDRESS',
    country: 'COUNTRY',
    city: 'CITY',
    state: 'STATE',
    zipcode: 'ZIPCODE',
    timezone: 'TIMEZONE'
  },
  modal: {
    title: 'Upgrade Subscription',
    current_subscription: 'Current Subscription',
    previous: 'Previous',
    checkout: 'Checkout',
    change_card: 'Change Credential',
    invoice_details: 'Invoice details',
    current: 'Current',
    request_processed: 'Your request has been processed',
    print_receipt: 'Print Receipt',
    download_receipt: 'Download Receipt',
    finish: 'Finish',
    due_today: 'Due Today',
    confirmation_description:
      'The payment cannot be modified after checking this window, are you sure you want to checkout?',
    total_amount: 'Total amount',
    remove: 'Remove',
    confirmation: 'Confirmation',
    delete_payment_description: 'Are you sure to delete this credential',
    door_added: 'door has been added',
    available_door_license: 'Available door licenses',
    confirm_door_activate:
      'Are you sure you want to assign {{doors}} door licenses?',
    not_enough_doors: 'You don\'t have enough door licenses available',
    click_buy: 'Click Buy to add the rest of the doors to your plan',
    buy_door: 'Buy {{doors}} door licenses',
    buy_more_licenses: 'Buy more licences'
  }
};

export default general;
