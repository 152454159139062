const general = {
  sites: 'Des sites',
  site: 'Site',
  door: 'Porte',
  add_site: 'Nouveau Site',
  edit_site: 'Site',
  edit_site_access: 'Modifier l\'accès au site',
  search_site: 'Site de recherche',
  copy_organization: 'Copier le compte',
  delete_site_message: 'Etes-vous sûr que vous voulez supprimer {{site}}?',
  notifications: 'Notifications',
  notification: 'Notification',
  events: 'Evénements',
  site_info: 'Informations sur le site',
  total_sites_assigned: 'Total des sites attribués',
  alerts: 'Alertes',
  add_notification: 'Nouvelle notification',
  send_to: 'Envoyer à',
  delete_contact_message: 'Etes-vous sûr de vouloir supprimer le contact {{contact}}?',
  invalid_email: 'Format d\'e-mail invalide',
  select_unselect_all: 'Sélectionner/Désélectionner Tout',
  notification_at_least_one: 'Sélectionnez au moins une notification',
  form: {
    name: 'Nom',
    address: 'Adresse',
    country: 'Pays',
    city: 'Ville',
    state: 'État',
    zip_code: 'Code postal',
    timezone: 'Fuseau horaire',
    contact_to: 'Contacter à',
    type_email: 'Tapez e-mail',
    send_notification: 'Envoyer une notification',
    lockdown: 'Lockdown (démarré, arrêté)',
    alarm_forced: 'Porte en alarme forcée',
    alarm_tampared: 'Porte en alarme sabotée',
    tamper: 'Altérer',
    battery_troubles: 'Problèmes de batterie',
    power_troubles: 'Problèmes d\'alimentation'
  },
  table: {
    name: 'NOM',
    address: 'ADRESSE',
    country: 'PAYS',
    city: 'VILLE',
    state: 'ETAT',
    zipcode: 'CODE POSTAL',
    timezone: 'FUSEAU HORAIRE'
  },
  modal: {
    title: 'Abonnement à la mise à niveau d\'impression',
    remove_title: 'Supprimer Subscription',
    previous: 'Précédent',
    checkout: 'Vérifier',
    change_card: 'Changer de certificat',
    invoice_details: 'Détails de la facture',
    current: 'Actuel',
    request_processed: 'Votre demande a été traitée',
    print_receipt: 'Imprimer le reçu',
    download_receipt: 'Télécharger le reçu',
    finish: 'Finir',
    due_today: 'Dû Aujourd\'hui',
    confirmation_description:
      'Le paiement ne peut pas être modifié après avoir vérifié cette fenêtre, Etes-vous sûr de vouloir procéder au paiement?',
    total_amount: 'Montant total',
    remove: 'Retirer',
    confirmation: 'Confirmation',
    delete_payment_description:
      'Voulez-vous vraiment supprimer cette certificat',
    door_added: 'porte a été ajoutée',
    available_door_license: 'Licences de porte disponible',
    confirm_door_activate:
      'Êtes-vous sûr de vouloir attribuer {{doors}} licences de porte?',
    not_enough_doors: 'Vous n\'avez pas assez de licences de porte disponibles',
    click_buy:
      'Cliquez sur Acheter pour ajouter le reste des portes à votre plan',
    buy_door: 'Acheter {{doors}} licences de porte',
    buy_more_licenses: 'Acheter plus de licences'
  }
};

export default general;
