// English imports
import { default as generalEn } from './general/en';
import { default as navigationMenuEn } from './navigationMenu/en';
import { default as loginEn } from './login/en';
import { default as forgotPasswordEn } from './forgotPassword/en';
import { default as usersEn } from './users/en';
import { default as rolesEn } from './roles/en';
import { default as permissionsEn } from './permissions/en';
import { default as dealersEn } from './dealers/en';
import { default as menuEn } from './menu/en';
import { default as testingToolEn } from './testingTool/en';
import { default as organizationEn } from './organization/en';
import { default as sitesEn } from './sites/en';
import { default as controllersEn } from './controllers/en';
import { default as doorsEn } from './doors/en';
import { default as holidayEn } from './holidays/en';
import { default as accesslevelEn } from './accesslevel/en';
import { default as schedulesEn } from './schedules/en';
import { default as cardsEn } from './cards/en';
import { default as eventsEn } from './events/en';
import { default as tasksEn } from './tasks/en';
import { default as dealerRequestEn } from './dealerRequest/en';
import { default as resourcesEn } from './resources/en';
import { default as lockdownEn } from './lockdown/en';
import { default as inputsEn } from './inputs/en';
import { default as outputsEn } from './outputs/en';
import { default as reportsEn } from './reports/en';
import { default as firmwareEn } from './firmware/en';
import { default as globalSettingsEn } from './globalSettings/en';
import { default as floorPlanEn } from './floorplan/en';
import { default as supportToolEn } from './supportTool/en';
import { default as interlocksEn } from './interlocks/en';
import { default as helpConfigurationEn } from './helpConfiguration/en';
import { default as zodEn } from './zod/en';

// Spanish imports
import { default as generalEs } from './general/es';
import { default as navigationMenuEs } from './navigationMenu/es';
import { default as loginEs } from './login/es';
import { default as forgotPasswordEs } from './forgotPassword/es';
import { default as usersEs } from './users/es';
import { default as rolesEs } from './roles/es';
import { default as permissionsEs } from './permissions/es';
import { default as dealersEs } from './dealers/es';
import { default as menuEs } from './menu/es';
import { default as testingToolEs } from './testingTool/es';
import { default as organizationEs } from './organization/es';
import { default as sitesEs } from './sites/es';
import { default as controllersEs } from './controllers/es';
import { default as doorsEs } from './doors/es';
import { default as holidayEs } from './holidays/es';
import { default as accesslevelEs } from './accesslevel/es';
import { default as schedulesEs } from './schedules/es';
import { default as cardsEs } from './cards/es';
import { default as eventsEs } from './events/es';
import { default as tasksEs } from './tasks/es';
import { default as dealerRequestEs } from './dealerRequest/es';
import { default as resourcesEs } from './resources/es';
import { default as lockdownEs } from './lockdown/es';
import { default as inputsEs } from './inputs/es';
import { default as outputsEs } from './outputs/es';
import { default as reportsEs } from './reports/es';
import { default as firmwareEs } from './firmware/es';
import { default as globalSettingsEs } from './globalSettings/es';
import { default as floorPlanEs } from './floorplan/es';
import { default as interlocksEs } from './interlocks/es';
import { default as supportToolEs } from './supportTool/es';
import { default as helpConfigurationEs } from './helpConfiguration/es';
import { default as zodEs } from './zod/es';

// French imports
import { default as generalFr } from './general/fr';
import { default as navigationMenuFr } from './navigationMenu/fr';
import { default as loginFr } from './login/fr';
import { default as forgotPasswordFr } from './forgotPassword/fr';
import { default as usersFr } from './users/fr';
import { default as rolesFr } from './roles/fr';
import { default as permissionsFr } from './permissions/fr';
import { default as dealersFr } from './dealers/fr';
import { default as menuFr } from './menu/fr';
import { default as testingToolFr } from './testingTool/fr';
import { default as organizationFr } from './organization/fr';
import { default as sitesFr } from './sites/fr';
import { default as controllersFr } from './controllers/fr';
import { default as doorsFr } from './doors/fr';
import { default as holidayFr } from './holidays/fr';
import { default as accesslevelFr } from './accesslevel/fr';
import { default as schedulesFr } from './schedules/fr';
import { default as cardsFr } from './cards/fr';
import { default as eventsFr } from './events/fr';
import { default as tasksFr } from './tasks/fr';
import { default as dealerRequestFr } from './dealerRequest/fr';
import { default as resourcesFr } from './resources/fr';
import { default as lockdownFr } from './lockdown/fr';
import { default as inputsFr } from './inputs/fr';
import { default as outputsFr } from './outputs/fr';
import { default as reportsFr } from './reports/fr';
import { default as firmwareFr } from './firmware/fr';
import { default as globalSettingsFr } from './globalSettings/fr';
import { default as floorPlanFr } from './floorplan/fr';
import { default as interlocksFr } from './interlocks/fr';
import { default as supportToolFr } from './supportTool/fr';
import { default as helpConfigurationFr } from './helpConfiguration/fr';
import { default as zodFr } from './zod/fr';

const translations = {
  en: {
    ...navigationMenuEn,
    general: generalEn,
    login: loginEn,
    forgotPassword: forgotPasswordEn,
    users: usersEn,
    roles: rolesEn,
    perm: permissionsEn,
    dealers: dealersEn,
    menu: menuEn,
    testingTool: testingToolEn,
    organization: organizationEn,
    sites: sitesEn,
    controllers: controllersEn,
    doors: doorsEn,
    holiday: holidayEn,
    accesslevel: accesslevelEn,
    schedules: schedulesEn,
    card: cardsEn,
    events: eventsEn,
    tasks: tasksEn,
    dealerRequest: dealerRequestEn,
    resources: resourcesEn,
    lockdown: lockdownEn,
    input: inputsEn,
    output: outputsEn,
    reports: reportsEn,
    firmware: firmwareEn,
    globalSettings: globalSettingsEn,
    floorplan: floorPlanEn,
    interlocks: interlocksEn,
    supportTool: supportToolEn,
    helpConfiguration: helpConfigurationEn,
    zod: zodEn,
  },
  es: {
    ...navigationMenuEs,
    general: generalEs,
    login: loginEs,
    forgotPassword: forgotPasswordEs,
    users: usersEs,
    roles: rolesEs,
    perm: permissionsEs,
    dealers: dealersEs,
    menu: menuEs,
    testingTool: testingToolEs,
    organization: organizationEs,
    sites: sitesEs,
    controllers: controllersEs,
    doors: doorsEs,
    holiday: holidayEs,
    accesslevel: accesslevelEs,
    schedules: schedulesEs,
    card: cardsEs,
    events: eventsEs,
    tasks: tasksEs,
    dealerRequest: dealerRequestEs,
    resources: resourcesEs,
    lockdown: lockdownEs,
    input: inputsEs,
    output: outputsEs,
    reports: reportsEs,
    firmware: firmwareEs,
    globalSettings: globalSettingsEs,
    floorplan: floorPlanEs,
    interlocks: interlocksEs,
    supportTool: supportToolEs,
    helpConfiguration: helpConfigurationEs,
    zod: zodEs,
  },
  fr: {
    ...navigationMenuFr,
    general: generalFr,
    login: loginFr,
    forgotPassword: forgotPasswordFr,
    users: usersFr,
    roles: rolesFr,
    perm: permissionsFr,
    dealers: dealersFr,
    menu: menuFr,
    testingTool: testingToolFr,
    organization: organizationFr,
    sites: sitesFr,
    controllers: controllersFr,
    doors: doorsFr,
    holiday: holidayFr,
    accesslevel: accesslevelFr,
    schedules: schedulesFr,
    card: cardsFr,
    events: eventsFr,
    tasks: tasksFr,
    dealerRequest: dealerRequestFr,
    resources: resourcesFr,
    lockdown: lockdownFr,
    input: inputsFr,
    output: outputsFr,
    reports: reportsFr,
    firmware: firmwareFr,
    globalSettings: globalSettingsFr,
    floorplan: floorPlanFr,
    interlocks: interlocksFr,
    supportTool: supportToolFr,
    helpConfiguration: helpConfigurationFr,
    zod: zodFr,
  }
};

export default translations;
