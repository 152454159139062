const dealerRequests = {
  header: 'Lista de Distribuidores',
  dealer_requests: 'Solicitudes de Distribuidores',
  search_dealers: 'Buscar Distribuidores',
  confirm_modal_cancel: 'No, cancelar',
  confirm_modal_confirm: 'Si, eliminar',
  request_pending: 'Solictudes Pendientes',
  approved: 'Aprobado',
  pending: 'Pendiente',
  denied: 'Denegado',
  in_review: 'En Revisión',
  approve: 'Aprobar',
  deny: 'Denegar',
  review: 'Revisar',
  close: 'Cerrar',
  all: 'Todo',
  submit: 'Enviar',
  cancel: 'Cancel',
  update: 'Actualizar',
  edit: 'Editar',
  profile: 'Perfil',
  history: 'Historia',
  email_notifications: 'Notificationes de email',
  email_modal_description: 'Esta lista de correo electrónico recibirá la solicitud de distribuidor',
  table: {
    company: 'COMPAÑÍA',
    full_name: 'NOMBRE COMPLETO',
    email: 'EMAIL',
    country: 'PAIS',
    date: 'FECHA',
    status: 'ESTADO'
  }
};

export default dealerRequests;
