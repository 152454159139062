import {
  Snackbar,
  Alert as MuiAlert,
  AlertTitle,
  AlertColor,
  SnackbarCloseReason,
  SnackbarOrigin,
  AlertProps
} from '@mui/material';

import { useSelector } from '../../store';
import Alert from 'interfaces/alert.interface';
import useAlert from '../../hooks/useAlert';

interface SnackProps {
  title?: string;
  duration?: number;
  origin?: SnackbarOrigin;
  variant?: AlertProps['variant'];
}

const Snack = ({
  title,
  duration = 5000,
  origin = { vertical: 'top', horizontal: 'center' },
  variant = 'standard'
}: SnackProps) => {
  const snack: Alert = useSelector((store: any) => store.snackAlert)
  const { closeAlert } = useAlert();

  const handleClose = (
    _event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason && reason === 'clickaway') {
      return;
    }

    closeAlert();
  };

  const { open, severity } = snack;

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={duration}
      anchorOrigin={origin}
    >
      <MuiAlert
        sx={{ width: '100%', borderRadius: '0em' }}
        severity={severity ? severity as AlertColor : undefined}
        onClose={handleClose}
        variant={variant}
      >
        {title && <AlertTitle>{title}</AlertTitle>}

        {snack.message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Snack;
